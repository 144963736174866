// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-colours-js": () => import("./../../../src/templates/colours.js" /* webpackChunkName: "component---src-templates-colours-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-makemodels-js": () => import("./../../../src/templates/makemodels.js" /* webpackChunkName: "component---src-templates-makemodels-js" */),
  "component---src-templates-makes-js": () => import("./../../../src/templates/makes.js" /* webpackChunkName: "component---src-templates-makes-js" */),
  "component---src-templates-status-js": () => import("./../../../src/templates/status.js" /* webpackChunkName: "component---src-templates-status-js" */),
  "component---src-templates-type-js": () => import("./../../../src/templates/type.js" /* webpackChunkName: "component---src-templates-type-js" */),
  "component---src-templates-vehicle-js": () => import("./../../../src/templates/vehicle.js" /* webpackChunkName: "component---src-templates-vehicle-js" */)
}

